import { Route, Routes, useLocation } from "react-router-dom";
import ActivateAccount from "../../pages/auth/Activate";
import Login from "../../pages/auth/Login";
import Logout from "../../pages/auth/Logout";
import Register from "../../pages/auth/Register";
import ResetPassword from "../../pages/auth/ResetPassword";
import ResetPasswordForm from "../../pages/auth/ResetPasswordForm";
import NotFound from "../../pages/NotFound";
import MyPatients from "../../pages/patients/MyPatients";
import Patient from "../../pages/patients/Patient";
import PlanView from "../../pages/plan/PlanView";
import UpsertPlan from "../../pages/upsertPlan/UpsertPlan";
import ChooseClinic from "../../pages/account/ChooseClinic";
import ChooseLicence from "../../pages/settings/ChooseLicence";
import MyAccount from "../../pages/settings/MyAccount";
import PrivateRoute from "./PrivateRoute";
import Settings from "../../pages/settings/Settings";
import TemplateView from "../../pages/templates/TemplateView";
import TemplatesMemo from "../../pages/templates/Templates";
import MyExercises from "../../pages/myExercises/MyExercises";
import Telerehab from "../../pages/telerehab/Telerehab";
import UpdatePassword from "../../pages/settings/UpdatePassword";
import Payment from "../../pages/account/Payment";
import Stats from "../../pages/account/Stats";
import Contact from "../../pages/Contact";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { parseGaPathname } from "../../helpers/helper";
import BannedAccount from "../../pages/account/BannedAccount";
import StripePortalLink from "../../pages/payment/StripePortalLink";
import TestEr from "../../pages/Test";
import PayNow from "../../pages/account/PayNow";
import AddPatient from "../../pages/patients/AddPatient";

const NavRoutes = () => {
  const location = useLocation();
  ReactGA.initialize("G-ZW195B8SWR");

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: parseGaPathname(location.pathname),
      title: parseGaPathname(location.pathname)
    });
  }, [location]);
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<MyPatients />} />
        {/* <Route path="/" element={<App />} /> */}
        <Route path="/patients" element={<MyPatients />} />
        <Route path="/add-patient" element={<AddPatient />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/account-banned" element={<BannedAccount />} />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route path="/my-exercises" element={<MyExercises />} />
        <Route path="/telerehab" element={<Telerehab />} />
        <Route path="/templates" element={<TemplatesMemo />} />
        <Route path="/template/:id" element={<TemplateView />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/choose-clinic" element={<ChooseClinic />} />
        <Route path="/patient/:idFromUrl" element={<Patient />} />
        <Route path="/patient/plan/:id" element={<PlanView />} />
        <Route path="/exercise-plan" element={<UpsertPlan />} />
        <Route path="/settings/:page" element={<Settings />} />
        <Route path="/settings/integrations/:page" element={<Settings />} />
        <Route path="/go-to-stripe" element={<StripePortalLink />} />
        <Route path="/stats" element={<Stats />} />
      </Route>
      <Route path="/choose-licence" element={<ChooseLicence />} />

      <Route path="login" element={<Login />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="/testEr55" element={<TestEr />} />
      <Route path="/payNow" element={<PayNow />} />
      <Route path="/register" element={<Register />} />
      <Route path="/auth/reset_password" element={<ResetPasswordForm />} />
      <Route path="/auth/activate/:code" element={<ActivateAccount />} />

      <Route path="/settings" element={<Settings />} />

      <Route path="/logout" element={<Logout />} />
      <Route path="/contact" element={<Contact onlyForm={false} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default NavRoutes;
