import { Button, Input, Radio, Form, Alert, Select, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PatientUpsert } from "../../types/Patient";
import MaskedInput from "antd-mask-input/build/main/lib/MaskedInput";
import { useGlobalContext } from "../../context/GlobalContext";
import { Language } from "../../types/General";
import { ImageType } from "../../types/Plan";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { post } from "../../api/Request";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import PhoneInput from "antd-phone-input";
import { useMediaQuery } from "react-responsive";
import MyPageHeader from "../../components/layout/MyPageHeader";
import Column from "../../components/layout/Col";

const AddPatient: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const location = useLocation();
  const { user } = useAuth();
  const { language } = useGlobalContext();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [patientExist, setPatientExist] = useState<null | number>(null);

  const exerciseImageType = [
    { key: 1, label: t("photo"), value: ImageType.photo },
    { key: 2, label: t("lineart"), value: ImageType.lineart }
  ];

  const checkCheckBox = (rule, value: string) => {
    if (!value) return Promise.reject(t("form.error.patientConsentRequired"));
    return Promise.resolve();
  };

  const formatDobByLanguage = (date: string, language: Language) => {
    if (!date) return "";
    return dayjs(date).format(
      language === Language.enUS ? "MM-DD-YYYY" : "DD-MM-YYYY"
    );
  };

  const checkDob = (rule, value: string) => {
    if (
      value === "" ||
      value === undefined ||
      value === null ||
      value === "__-__-____"
    )
      return Promise.resolve();

    if (value) {
      if (
        dayjs(
          value,
          language === Language.enUS ? "MM-DD-YYYY" : "DD-MM-YYYY",
          true
        ).isValid() === false
      )
        return Promise.reject(t("form.error.invalidDate"));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const initialData: PatientUpsert = {
      id: 0,
      pms_id: queryParams.get("pms_id") || "",
      first_name: queryParams.get("first_name") || "",
      last_name: queryParams.get("last_name") || "",
      title: queryParams.get("title") || "",
      email: queryParams.get("email") || "",
      phone: queryParams.get("phone") || "",
      dial_code: queryParams.get("dial_code")
        ? Number(queryParams.get("dial_code"))
        : user?.settings.default_phone_dial_code,
      dob: queryParams.get("dob") || "",
      image_type:
        queryParams.get("image_type") === "lineart"
          ? ImageType.lineart
          : ImageType.photo,
      patient_consent: true,
      send_login_details: false, // or true, depending on your requirement
      clinic_id: user?.clinic.id
    };

    form.setFieldsValue({
      ...initialData,
      dob: formatDobByLanguage(initialData.dob ?? "", language),
      phone3: {
        countryCode: initialData.dial_code,
        phoneNumber: initialData.phone
      }
    });
  }, [location.search, form]);

  const submit = () => {
    form
      .validateFields()
      .then(values => {
        setLoading(true);
        post("patient/upsert", values)
          .then(response => {
            navigate(`/patient/${response.data.id}`);
          })
          .catch(error => {
            console.error("Error submitting form:", error);
          })
          .finally(() => setLoading(false));
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  const checkForDuplicatePatient = () => {
    try {
      const patient = form.getFieldsValue();
      if (patient.first_name.length > 0 && patient.last_name.length > 0)
        post("patient/checkForDuplicate", patient).then(r => {
          if (r.data.patient_id) setPatientExist(r.data.patient_id);
          else setPatientExist(null);
        });
    } catch (error) {
      console.error("Error checking for duplicate patient:", error);
      return null;
    }
  };

  return (
    <Column size="medium" style={{ marginTop: -50 }} key="col" className="box">
      <MyPageHeader title={t("patient.addNewPatient")}></MyPageHeader>

      <div className="box">
        <Form
          form={form}
          id="patientForm"
          layout="vertical"
          onValuesChange={(changedValues, allValues) => {
            // Handle any changes if needed
          }}
        >
          <Form.Item hidden name="id">
            <Input required />
          </Form.Item>
          <Form.Item hidden name="pms_id">
            <Input required />
          </Form.Item>
          <Form.Item label={t("account.chooseClinic")} name="clinic_id">
            <Select
              showSearch
              value={user ? user.clinic.id : 0}
              optionFilterProp="children"
              filterOption={true}
            >
              {user &&
                user.clinics?.map(c => (
                  <Select.Option value={c.id} key={c.id}>
                    {c.clinic_name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t("firstName")}
            name="first_name"
            rules={[
              {
                required: true,
                message: t("form.fieldRequired", { field: t("firstName") })
              }
            ]}
          >
            <Input onBlur={checkForDuplicatePatient} />
          </Form.Item>
          <Form.Item
            label={t("lastName")}
            name="last_name"
            rules={[
              {
                required: true,
                message: t("form.fieldRequired", { field: t("lastName") })
              }
            ]}
          >
            <Input onBlur={checkForDuplicatePatient} />
          </Form.Item>
          <Form.Item label={t("patient.title2")} name="title">
            <Input placeholder={t("patient.titlePlaceholder")} />
          </Form.Item>
          <Form.Item
            label={t("form.email")}
            name="email"
            rules={[
              {
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email"
              }
            ]}
            normalize={value => value.trim()}
          >
            <Input onBlur={checkForDuplicatePatient} />
          </Form.Item>
          {patientExist !== null && (
            <Alert
              message={t("patient.alreadyExists")}
              description={
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    navigate("/patient/" + patientExist);
                  }}
                >
                  {t("patient.viewPatient")}
                </Button>
              }
              type="warning"
              showIcon
            />
          )}

          {user?.settings.use_patient_email_cc_and_bcc_fields === true && (
            <>
              <Form.Item
                label={t("form.emailCC")}
                name="email_cc"
                rules={[
                  {
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email"
                  }
                ]}
                normalize={value => value.trim()}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("form.emailBCC")}
                name="email_bcc"
                rules={[
                  {
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email"
                  }
                ]}
                normalize={value => value.trim()}
              >
                <Input />
              </Form.Item>
            </>
          )}

          <Form.Item label={t("phone")} name="phone3">
            <PhoneInput
              enableSearch={true}
              onInput={event => {
                let value = event.target.value;
                let parts = value.split(" ");
                if (parts[1] && parts[1].charAt(0) === "0") {
                  parts[1] = parts[1].slice(1);
                }
                event.target.value = parts.join(" ");
              }}
              disableParentheses={true}
              preferredCountries={["uk", "us", "ca", "au"]}
            />
          </Form.Item>

          <Form.Item
            name="dob"
            label={
              language === Language.enUS
                ? t("form.dob") + " " + t("form.format-mdy")
                : t("form.dob") + " " + t("form.format-dmy")
            }
            rules={[
              {
                validator: checkDob
              }
            ]}
          >
            <MaskedInput
              mask="00-00-0000"
              size="large"
              placeholder={
                language === Language.enUS ? "mm-dd-yyyy" : "dd-mm-yyyy"
              }
            />
          </Form.Item>
          <Form.Item label={t("form.exerciseImageType")} name="image_type">
            <Radio.Group
              options={exerciseImageType}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>

          <Form.Item
            tooltip={t("help.patientConsent")}
            name="patient_consent"
            rules={[
              {
                required: true,
                validator: checkCheckBox
              }
            ]}
            valuePropName="checked"
          >
            <Checkbox>{t("form.patientConsent")}</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" onClick={submit} loading={loading}>
              {t("patient.addNewPatient")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Column>
  );
};

export default AddPatient;
